import Vue from 'vue'
import vSelect from 'vue-select'
import axios from "axios";
import $ from 'jquery'
//import axios from "axios";
import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)
Vue.component('v-select', vSelect)
import moment from "moment";
import Datepicker from 'vuejs-datepicker';
import DualListBox from "dual-listbox-vue";

export default {
    components: {
        vSelect,
        axios,
        Datepicker,
        DualListBox
    },
    data: function() {
        return {
            pageTitle: "Compte de résultat",
            base_url: Vue.BASE_URL,
            moreParams: {},
            crudmodaltitle: "",
            filtremodaltitle: "",
            listeCompte: [],
            plan_comptable_id: "",
            filtretexte : "",
            montant_total_debit: 0,
            montant_total_credit: 0,

            filtreDate: {
                dateDebut:"",
                dateFin:""
            },
            motCle: "",
            tabledata: [],
            json_fields: {
                'COMPTE': 'compte',
                'INTITULE': 'description',
                'RAN DEBIT': 'ran_credit',
                'RAN CREDIT': 'ran_debit',
                'ENCOURS DEBIT': 'montant_debit',
                'ENCOURS CREDIT': 'montant_credit',
                'CUMUL DEBIT': 'cumul_debit',
                'CUMUL CREDIT': 'cumul_credit'
            },
            json_data: [],
            json_meta: [
                [{
                    'key': 'charset',
                    'value': 'utf-8'
                }]
            ],
            dateOptions: {
                format: 'DD/MM/YYYY',
            },
            disabledDates: {
                to: new Date(Date.now())
            },
            DualListSource: [],
            DualListDestination: [],
            date_exercice: {
                date_debut: "",
                date_fin: ""
            },
            afficherToutExercice: "",
            inclurebrouillard: "true",
            repporanouveau: "true",
            grandLivreData: [],
            printPDFValue:"",
            total_mouvement: {
                debit_report: 0,
                credit_report: 0,
                debit_encours: 0,
                credit_encours: 0,
                debit_cumul: 0,
                credit_cumul: 0
            },
            resultatParam: []

        }
    },
    methods: {
        onChangeList: function ({ source, destination }) {
            this.DualListSource = source;
            this.DualListDestination = destination;
            this.filtretexte = JSON.stringify(destination);
        },
        onExport() {

            this.tabledata = this.grandLivreData;
            
            console.log(this.tabledata);
            this.json_data = this.tabledata;
            $("#excel-download").trigger("click");
            
        },
        getDateExercice() {
            var that = this;
            axios.get(that.base_url+"/exercice/get/1").then(response => {
                console.log(response.data);
                that.date_exercice.date_debut = moment(response.data[0].date_debut).format('DD/MM/YYYY');
                that.date_exercice.date_fin = moment(response.data[0].date_fin).format('DD/MM/YYYY');

                this.getDashboardData();
            });

        },
        getDashboardData() {
            var that = this;
            axios.get(that.base_url+"/appparams/getresultat/?date_debut="+this.date_exercice.date_debut+"&date_fin="+this.date_exercice.date_fin).then(response => {
                this.resultatParam = response.data;
            });

            this.url_pdf_devis = that.base_url+"/appparams/printgl1/?date_debut="+this.filtreDate.dateDebut+"&date_fin="+this.filtreDate.dateFin;

        },
        onPrintGl1() {
            // this.closeFiltreModal();
            var that = this;

            axios.get(this.base_url+"/balance/printgl1").then(function(response) {
                
                that.printPDFValue = response.data;
                window.open(that.base_url+"/"+response.data);
                // console.log(that.base_url+"/"+response.data);
                // window.open(that.base_url+"/"+response.data);

            });
        }
    },
    computed: {
        
    },
    created: function() {
        this.$emit('change-page', this.pageTitle);
        this.motCle = "";
        this.criteriacol = "";
        
        var currentDateWithFormat = moment().format('DD/MM/YYYY');
        this.filtreDate.dateDebut = currentDateWithFormat;
        this.filtreDate.dateFin = currentDateWithFormat;

        this.getDateExercice();

        // this.onSubmitSearch();
        
        //this.initListCompte('');
    },
    mounted: function() {
        // var that = this;
        // this.initListCompte('');
        // this.openFiltreModal();

    }
}